<template>
  <div>
    <Header titleurl="secondhouse" :iscontactTel="iscontactTel"  v-on:closeShow="closeShow"></Header>
    <div class="details">
      <div class="search">
        <div class="inner">
          <div class="container_breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/rentinghouse' }">{{result.cityName}}出租房</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/rentinghouse',query:{countyCode:result.screenRegionId}}">{{result.countyName}}出租房</el-breadcrumb-item>
				<el-breadcrumb-item>{{!!result.communityName?result.communityName:""}}</el-breadcrumb-item>
			</el-breadcrumb>
          </div>
		  <div class="title-collecte">
			<div class="title">{{!!result.title?result.title:''}}</div>
			<!-- <div class="collectionreport">
				<div class="icon-item">
					<div v-if="collectionId === 0" @click="collection(0)">
						<img src="../../assets/img/xingoff.png" />
					</div>
					<div v-if="collectionId != 0" @click="collection(1)">
						<img src="../../assets/img/xingon.png" />
					</div>
					<div class="icon-font">
						加入收藏
					</div>
				</div>
				<div class="icon-item">
					<div>
						<img src="../../assets/img/scan.png" />
					</div>
					<div class="icon-font">
						扫一扫
					</div>
				</div>
				<div class="icon-item">
					<div>
						<img src="../../assets/img/report.png" />
					</div>
					<div class="icon-font">
						举报
					</div>
				</div>
			</div> -->
		  </div>
        </div>
      </div>
      <div class="inner">
        <div class="overview fl">
          <div class="img">
            <div class="imgContainer">
              <img :src="posimgs[0].imgUrl" />
            </div>
            <div class="thumbnail">
              <el-button class="pre" @click="pre()" :disabled="isButton" >&lt;</el-button>
              <ul class="smallpic">
                <div class="pos">
                  <li v-for="(item,index) in posimgs" :class="{active: posactive ==index}" :key="index" @click="posimg(index,item)">
                    <img :src="item.imgUrl" />
                  </li>
                </div>
              </ul>
              <el-button class="next" @click="next()" :disabled="isButton">&gt;</el-button>
            </div>
          </div>
          <div class="contents inner">
          </div>
        </div>
		<div class="house-msg fl">
			<el-row class="house-msg-price">
				<span class="strongfont light">{{result.rent}}</span>
				<span class="light f16"> 元 /月</span>
				<span class="house-deposit">{{result.payMode}}</span> 
			</el-row>
			<div class="house-msg-intro mt">
				<el-row>
					<span class="grey f14 mr">租赁方式：</span>
					<span class="f14 black">{{result.leaseMode===1?"整租":result.leaseMode===2?"合租-"+result.leasePlace:''}}</span>
				</el-row>
				<el-row>
					<span class="grey f14 mr">房屋类型：</span>
					<span class="f14 black">
						<span  class="mr">{{!!result.roomCount?result.roomCount:''}}室{{result.parlourCount?result.parlourCount:''}}厅{{result.bathroomCount?result.bathroomCount:''}}卫  </span>
						<span>{{result.area}}平  </span>    
						<span>   {{result.decoration}}</span>
					</span>
				</el-row>
				<el-row>
					<span class="grey f14 mr">朝向楼层：</span>
					<span class="f14 black">
						<!-- <span class="mr">{{result.house.faceTo}} </span> -->
						<span>
							<!-- 中层 /  -->
							{{result.floor}}层  /  {{result.floorAll}}层</span>    
					</span>
				</el-row>
				<el-row>
					<span class="grey f14 mr">所在小区：</span>
					<span class="f14 black">
						<span>{{result.communityName}} </span>
						<!-- <span class="f12 grey">(在租 <span class="blue">62</span> 套)</span>    -->
					</span>
				</el-row>
				<el-row>
					<span class="grey f14 mr">所属区域：</span>
					<span class="f14 black">
						<span>{{result.countyName}}   </span>
						<!-- <span class="mr">  花园路</span> -->
						<!-- <span class="f12 grey">距离地铁2号线东风路站150米</span>   -->
					</span>
				</el-row>
				<el-row>
					<span class="grey f14 mr">详细地址：</span>
					<span class="f14 black">
						<span class="mr">{{result.address}}   </span>
						<span><a href="#containerAmap" class="f12 blue">查看地图</a></span>
					</span>
				</el-row>
				<el-row class="house-phone" >
					<el-button type="danger" @click="callphone()"><i class="el-icon-phone"></i>电话联系他</el-button>
				</el-row>
				 <el-dialog
					title=""
					:visible.sync="dialogVisible"
					width="30%">
					<span class="isphone">{{!!result.contactTel?result.contactTel:""}}</span>
				</el-dialog>
			</div>
		</div>
		<!-- <div class="house-people fr">
			<el-row class="tc">
				<img src="../../assets/img/defaultheadpic.png" width="80" height="80" />
			</el-row>
			<el-row class="tc mt10">
				<div class="f16">{{result.title}}(中介)</div>
			</el-row>
			<el-row class="tc mt10">
				<div class="f12">河南凯业股份有限公司</div>
			</el-row>
			<el-row class="tc border-p  mt10">
				<div class="score-item fl mr">
					<span class="score-result">较快</span>
					<span class="score-title">电话回复</span>
				</div>
				<div class="score-item fl mr">
					<span class="score-result">较好</span>
					<span class="score-title">房源质量</span>
				</div>
				<div class="score-item fl">
					<span class="score-result">一般</span>
					<span class="score-title">服务水平</span>
				</div>
			</el-row>
		</div> -->
      </div>
	  <div class="inner clear second">
		  <div class="house-detail fl">
			  <div class="detail" id="housedetail">
				<div class="f20 fl"><a href="#housedetail" class="black">房源详情</a></div>
			  	<div class="f20 fl" ><a href="#areadetail" class="grey areadetail">小区详情</a></div>
			  </div>
			  <el-row class="detail-tips clear">
				  签约前切勿付 <span class="light">订金、押金、租金</span> 等一切费用！务必 实地看房， 查验房东和房屋证件！
			  </el-row>
			  <el-row class="detail-icon">
				  <!-- //房屋设施
				  {{result.facilities}} -->
				  <ul>
					  <li v-for="(item,index) in facilitiesPublic" :key="index">
						<i :class="item.icon"><div class="f14 tc">{{item.sinogram}}</div></i>
					  </li>
				  </ul>
				  
			  </el-row>
			  <el-row class="detail-lights mt">
				  <div class="f16 black  fl mr">房屋亮点</div>
				  <ul class="greylight f14 intro">
					  <li>{{result.highlights}}</li>
				  </ul>
			  </el-row>
			  <el-row class="detail-lights mt">
				  <div class="f16 black  fl mr">出租要求</div>
				  <ul class="greylight f14 intro">
					  <li>{{result.require}}</li>
				  </ul>
			  </el-row>
			  <el-row class="detail-lights mt">
				  <div class="f16 black  fl mr">房源描述</div>
				  <div class="greylight f14 intro">
					  {{result.describe}}
				  </div>
			  </el-row>
			  <el-row class="detail-imgs">
				  <el-image v-for="(url,index) in result.houseImgList" :key="index" :src="url.imgUrl" style="width: 340px; height: 230px" lazy ></el-image>
			  </el-row>
			  <el-row class="mt house-area" id="areadetail">
				  <el-image :src="require('../../assets/img/index_c.png')" class="fl mr"  style="width: 70px; height: 70px"></el-image>
				  <div class="f16 black">小区:<span>{{result.communityName}}</span></div>
				  <div class="nowhouse tc fr">
					  <div>{{result.leaseCount}}</div>
					  <span class="grey f14">在租房源</span>
				  </div>
				  <div class="greylight f14">小区地址:<span>{{result.address}}</span></div>
				  <!-- <div class="blue f14"><i class="el-icon-finished">房租走势</i></div> -->
			  </el-row>
			  <!-- <el-row class="house-company">
					<span class="grey f14 mr">建筑年代：</span>
					<span class="f14 black">
						<span>{{result.buildYear}}</span>
					</span>
			  </el-row>
			  <el-row class="house-company">
					<span class="grey f14 mr">建筑类型：</span>
					<span class="f14 black">
						<span>{{result.buildType}}</span>
					</span>
			  </el-row> -->
			  <el-row class="house-company">
					<span class="grey f14 mr">物业公司：</span>
					<span class="f14 black">
						<span>{{result.property}}</span>
					</span>
			  </el-row>
			  <el-row class="house-company">
					<span class="grey f14 mr">物业费用：</span>
					<span class="f14 black">
						<span>{{result.propertyFee?result.propertyFee+'元/平米/月':""}}</span>
					</span>
			  </el-row>
			  <!-- <el-row class="house-company">
					<span class="grey f14 mr">所属商圈：</span>
					<span class="f14 black">

					</span>
			  </el-row> -->
			  <el-row class="f14 black mt">
				  小区地图
			  </el-row>
				<div id="containerAmap">
					<el-amap
						vid="amaprentinghousedetail"  
						:center="center"
						:zoom="zoom"  
						class="amap-demo">
						<el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
					</el-amap>
				</div>

		  </div>
		  <div class="house-recommend fr">
			<div class="detail">
				<span class="black f20 fl">推荐房源</span>
			</div>
			<div class="house-recommend-item clear">
				<ul>
					<li v-for="(item,index) in recommends" :key="index" @click="rentinghousedetail(item.id)">
						<el-image src="../../assets/img/sechouse1.png" class="fl mr" style="width:170px;height:120px"></el-image>
						<div class="f20 greylight house-name">{{item.communityName}}</div>
						<div class="grey f14 house-room-item">{{item.roomCount?item.roomCount+'室':'0室'}}{{item.parlourCount?item.parlourCount+'厅':'0厅'}}{{item.bathroomCount?item.bathroomCount+'卫':'0卫'}}{{item.area?'/'+item.area+'平':''}}</div>
						<label class="grey f14 mr">
							{{item.leaseMode===1?"整租":item.leaseMode===2?"单租":""}}
						</label>
						<div class="f20 light"><span class="font-item">{{item.rent}}</span><span class="f14 light">元/月</span></div>
					</li>
				</ul>
			</div>
		  </div>

	  </div>
       
    </div>
    <Footer></Footer>
  </div>  
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import {leaseinfo,leasesimilarlist } from '@/api/rentinghouse'
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var accesstoken =getCookieByName('access_token');
export default {
  name: 'housedetail',
  components:{
    Header,Footer
  },
  data() {
    const self = this
    return {
	  facilitiesArray:[
		  {name:'bed', sinogram:'床',icon:'el-icon-receiving'},
		  {name:'wardrobe', sinogram:'衣柜',icon:'el-icon-film'},
		  {name:'sofa', sinogram:'沙发',icon:'el-icon-bank-card'},
		  {name:'television', sinogram:'电视',icon:'el-icon-data-board'},
		  {name:'refrigerator', sinogram:'冰箱',icon:'el-icon-refrigerator'},
		  {name:'washingMachine', sinogram:'洗衣机',icon:'el-icon-wallet'},
		  {name:'airconditioner', sinogram:'空调',icon:'el-icon-light-rain'},
		  {name:'heater', sinogram:'热水器',icon:'el-icon-hot-water'},
		  {name:'broadband', sinogram:'宽带',icon:'el-icon-mobile-phone'},
		  {name:'heating', sinogram:'暖气',icon:'el-icon-guide'},
		  {name:'gasstove', sinogram:'燃气灶',icon:'el-icon-wallet'},
		  {name:'balcony', sinogram:'阳台',icon:'el-icon-sunny'},
		  {name:'toilet', sinogram:'卫生间',icon:'el-icon-toilet-paper'},
		  {name:'smartlock', sinogram:'智能门锁',icon:'el-icon-set-up'},
		  {name:'hood', sinogram:'油烟机',icon:'el-icon-no-smoking'},
		  {name:'cancook', sinogram:'可做饭',icon:'el-icon-tableware'},
	  ],
	  facilitiesPublic:[],
	  id:'',
	  recommends:[],
	  isButton:false,
	  dialogVisible:false,
	  iscontactTel:false,
	  result:{
		  communityInfo:{},
		  floor:{},
		  gisCoord:{},
		  house:{},
		  rentHouse:{},
		  unit:{}
	  },
	  posactive:0,
	//   collectionId:0,
      posimgs:[{
		imgUrl:require('@/assets/img/jinxiu3.jpg'),
	  }],
	  markers: [],
      center: [113.624763, 34.753257],
      zoom: 15,
	  // address: '',
	  lng: 113.624763,
      lat: 34.753257,
      address:'',
    };
  },
  mounted(){
	this.id = this.$route.query.id
	this.details()
	this.leasesimilarlists()
  },
  methods: {
	// collection(val) {

	// },
	//出租详情
	details(){
		const self = this
		leaseinfo({id:this.id}).then(res=>{
			if(res.data.code===1){
				this.result = res.data.result
				if(res.data.result.houseImgList.length>0){
					this.posimgs = res.data.result.houseImgList
					this.posactive = 0
				}
				if(res.data.result.lnglatAmap!=""){
					let lnglatAmap = res.data.result.lnglatAmap.split(",")
					this.center = lnglatAmap
					this.markers.push(lnglatAmap)
				}
				if(res.data.result.facilities.length>0){//整租的设施
					let facilities = res.data.result.facilities.split(",")
					facilities.forEach(function(o,p){
						self.facilitiesArray.forEach(function(m,n){
							if(o === m.name){
								self.facilitiesPublic.push({name:m.name,sinogram:m.sinogram,icon:m.icon})
							}
						})
					})
				}
				if(res.data.result.leaseMode===2&&res.data.result.leasePlaceFacilities.length>0){//单间的设施加上公共设施
					let leasePlaceFacilities = res.data.result.leasePlaceFacilities.split(",")
					leasePlaceFacilities.forEach(function(o,p){
						self.facilitiesArray.forEach(function(m,n){
							if(o === m.name){
								let isexist=self.facilitiesPublic.filter(v=>v.name===o).length>0
								if(isexist){
								}else{
									self.facilitiesPublic.push({name:m.name,sinogram:m.sinogram,icon:m.icon})
								}
							}
						})
					})
				}
			}else{
				window.history.go(-1)
			}
		})
	},
	//目标租赁房源相似房源列表
	leasesimilarlists(){
		leasesimilarlist({id:this.id,pageSize:10}).then(res=>{
			this.recommends = res.data.result.list
		})
		
	},
	//点击推荐类似房源
	rentinghousedetail(val){
		this.$router.push({path:'/rentinghouse/rentinghousedetail',query:{id:val}})
		this.id = val
		this.details()
		this.leasesimilarlists()
	},
	//照片向左一个
	pre(){
		this.isButton = true
		const self = this
		if(this.posactive==0){
			this.posactive = this.posimgs.length-1
			this.$(".imgContainer>img").attr("src",this.posimgs[this.posimgs.length-1].imgUrl)
			let poslenth = (this.posactive-2)*130
			if(this.posimgs.length<3){
			}else{
				let poslenth = (this.posactive-2)*130
				this.$(".smallpic>.pos").css("left",-poslenth+"px")
			}
			this.$(".smallpic>.pos").css("left",-poslenth+"px")
		}else{
			this.posimgs.forEach(function(item,i){
				if(self.posactive == i){
					if(self.posactive ===1){
						self.$(".smallpic>.pos").css("left","0")
					}else if(self.posactive < self.posimgs.length-4){
						self.$(".smallpic>.pos").css("left",(parseInt(self.$(".smallpic>.pos").css("left"))+130))
					}
					self.posactive = i-1
					self.$(".imgContainer>img").attr("src",self.posimgs[i-1].imgUrl)
					
				}
			})
		}
		setTimeout(function(){
			self.isButton = false
		},200)
	},
	//照片向右一个
	next(){
		this.isButton = true
		const self = this
		const active = this.posactive
		if(active==this.posimgs.length-1){
			this.posactive = 0
			this.$(".smallpic>.pos").css("left","0")
			this.$(".imgContainer>img").attr("src",self.posimgs[0].imgUrl)
		}else{
			this.posimgs.forEach(function(item,i){
				if(active== i){
					self.posactive = i+1
					if(active>=2){
						self.$(".smallpic>.pos").css("left",(parseInt(self.$(".smallpic>.pos").css("left"))-130))
					}
					self.$(".imgContainer>img").attr("src",self.posimgs[i+1].imgUrl)
				}
			})
		}
		setTimeout(function(){
			self.isButton = false
		},200)
	},
    posimg(index,val){
      this.posactive = index;
	  this.$(".imgContainer>img").attr("src",val.imgUrl)
	},
	//电话联系他
	callphone(){
		console.log("++++++++")
		if(accesstoken!=undefined){
			this.dialogVisible = true
		}else{
			this.iscontactTel = true
		}
	},
	//header头部传过来关闭登录框的操作
	closeShow(val){
		this.iscontactTel = val
	},
    // housedetail() {

		
    //   this.$router.push('/rentinghouse/rentinghousedetail');
    // },
  },
};
</script>
<style scoped>
	ol,ul{
		list-style: none;
	}
	.search{
		width: 100%;
		height: 100px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		border-bottom: 1px solid #f5f5f5;
	}
	.search, .title{
		margin-top: 20px;
		overflow: hidden;/*超出部分隐藏*/
		white-space: nowrap;/*不换行*/
		text-overflow:ellipsis;/*超出部分省略号显示*/
	}
	.fl{
		float: left;
	}
	.fr{
		float: right;
	}
	.inner{
		width: 1200px;
		margin: 0 auto;
	}
	.container_breadcrumb{
		font-size: 14px;
		line-height: 1;
	}
	.details .inner .title-collecte .title{
		color: #2c3e50;
		font-size: 24px;
		font-weight: 400;
	}
	.details .inner .title-collecte .collectionreport{
		margin-top: -35px;
		float: right;
	}
	.details .inner .title-collecte .collectionreport .icon-item{
		text-align:center;
		float:left;
		margin-left: 30px;
		cursor: pointer;
	}
	.details .inner .title-collecte .collectionreport .icon-item .icon-font{
		font-size: 1.15rem;
		line-height: 2.5rem;
		margin-top: 5px;
	}
	.details .inner .house-msg{
		margin-top: 30px;
		width: 400px;
		height: 385px;
	}
	.details .inner .house-msg .house-msg-price .strongfont{
		font-weight: 400;
		font-size: 34px;
	}
	.light{
		color: #ff552e;
	}
	.grey{
		color: #888;
	}
	.greylight{
		color: #555555;
	}
	.black{
		color: #333333;
	}
	.blue{
    	color: #0091d7;
	}
	.f12{
		font-size: 12px;
	}
	.f14{
		font-size: 14px;
	}
	.f16{
		font-size: 16px;
	}
	.f20{
		font-size: 20px;
	}
	.clear{
		clear:both;
	}
	.details .inner .house-msg .house-msg-price .house-deposit{
		color: #888;
		font-size: 14PX;
		margin-right: 4PX;
		margin-left: 20px;
	}
	.details .inner .house-msg .house-msg-intro{
		width: 400px;
	}
	.details .inner .house-msg .house-msg-intro  .el-row span{
		line-height: 40px;
	}
	.details .inner .house-phone{
		margin-top: 35px;
	}
	.isphone{
		font-size: 20px;
	}
	.details .inner .house-phone .el-icon-phone{
		color: white;
		font-size: 40px;
		position: absolute;
		left: 20px;
	}
	.details .inner .house-phone .el-button{
		color: white;
		background-color:  #ff552e;
		width: 300px;
		border-radius: 0%;
		line-height: 45px;
		font-size: 18px;
	}
	.details .inner .house-people{
		background: #fcfcfc;
		border-radius: 2PX;
		border: 1px solid #f2f0ed;
		margin-top: 16PX;
		padding: 15PX;
		width: 220PX;
		height: 385PX;
	}
	.details .inner .house-people .border-p{
		padding: 16PX 15px;
		border-bottom: 1px solid #f2f0ed;
		font-size: 12PX;
	}
	.details .inner .house-people .border-p .score-item .score-result{
		display: block;
		color: #333;
		font-size: 14PX;
		line-height: 14PX;
		padding-bottom: 10PX;
	}
	.details .inner .house-people .border-p .score-item .score-title{
		display: block;
		color: #999;
		font-size: 12PX;
		line-height: 12PX;
	}
	.second{
		position: relative;
		top: 80px;
	}
	.details .second .house-detail{
		width: 750px;
	}
	.details .second .house-detail .detail .areadetail:hover{
		color: #333333;
	}
	.details .second .house-detail .detail div{
		margin-right: 50px;
	}
	.details .second .house-detail .detail-tips{
		line-height: 100px;
	}
	.details .second .house-detail .detail-icon i{
		font-size: 36px;
		line-height: 30px;
		text-align: center;
	}
	.details .second .house-detail .detail-icon ul li{
		float: left;
		width: 120px;
		margin-bottom: 20px;
		text-align: center;
	}
	.details .second .house-detail .detail-lights ul li{
		display: inline-block;
		margin-right: 40px;
		line-height: 25px;
	}
	.details .second .house-detail .detail-lights .intro{
		display: inline-block;
		width: 600px;
		line-height: 25px;
	}
	.details .second .house-detail .detail-imgs{
		margin-top: 100px;
	}
	.details .second .house-detail .detail-imgs .el-image{
		width: 340px;
		margin:0 10px 6px 0;
	}
	.details .second .house-detail .nowhouse div{
		line-height: 10px;
		margin-bottom: 15px;
		font-size: 24px;
	}	
	.details .second .house-detail .house-area{
		margin-bottom: 50px;
		padding-top: 30px;
	}	
	.details .second .house-company span{
		line-height: 30px;
	}
	.details .second .house-recommend{
		width: 420px;
	}
	.details .second .house-recommend .house-recommend-item{
		padding-top: 20px;
	}
	.details .second .house-recommend .house-recommend-item ul li{
		width: 440px;
		clear: both;
		height: 150px;
		cursor: pointer;
	}
	.details .second .house-recommend .house-recommend-item .house-room-item{
		margin: 6px 0 4px 0;
	}

	.details .second .house-recommend .house-recommend-item .house-name{
		white-space:nowrap;	
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.details .second .house-recommend .house-recommend-item .font-item{
		font-weight: bold;
		line-height: 40px;
	}


	.tc{
		text-align: center;
	}
	.mt{
		margin-top: 20px;
	}
	.mt10{
		margin-top: 10px;
	}
	.mr{
		margin-right: 20px;
	}
	.overview{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 500px;
		height: 385px;
		margin-top: 10px;
	}
	.img .imgContainer{
		width: 460px;
		height: 340px;
		position: relative;
	}
	.img .imgContainer img{
		width: 460px;
		height: 340px;
		position: relative;
	}
	.img .thumbnail{
		position: relative;
		margin-top: 8px;
		border: 0px;
		width: 460px;
		overflow: hidden;
		height: 82px;	
	}
	.img .thumbnail .pre{
		position: absolute;
		top: 0;
		line-height: 82px;
		width: 30px;
		cursor: pointer;
		padding: 0;
		text-align: center;
		font-size: 20px;
		color: #cac9c9;
		opacity: .8;
		filter: alpha(opacity=80);
		background-color: #000;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;	
	}
	.img .thumbnail ul{
		margin-left: 31px;
		width: 640px;
		overflow: hidden;
		height: 82px;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		position: relative;	
	}
	.img .thumbnail ul .pos{
		left: 0px;
		width: 10000000000%;
		overflow: hidden;
		display: inline-block;
		position: absolute;
		-webkit-transition: all .3s ease;
		-o-transition: .3s ease all;
		transition: all .3s ease;	
	}
	.img .thumbnail .next{
		right: 0;
		position: absolute;
		top: 0;
		line-height: 82px;
		width: 30px;
		cursor: pointer;
		padding: 0;
		text-align: center;
		font-size: 20px;
		color: #cac9c9;
		opacity: .8;
		filter: alpha(opacity=80);
		background-color: #000;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;		
	}
	.img .thumbnail ul .pos li img{
		width: 100%;
		height: 100%;
	}	
	.img .thumbnail ul .pos li{
		width: 120px;
		height: 82px;
		margin:0 5px;
		text-align: center;
		display: inline-block;
		opacity: .5;
	}
	.img .thumbnail ul .pos .active{
		opacity: 1;
	}
	.details .overview .contents{
		width: 380px;
		line-height: 1;
	}
	details a:link{
		color: #9b9ea0;
		text-decoration: none;	 
	}
	a{
		outline: none;	
	}








	#containerAmap{
		clear: both;
		width: 750px;
		margin: 20px 0;
		height: 320px; 
	}
</style>