import http from '@/utils/http'

//筛选条件
export const screen = (params) =>{
    return http.get('/house/leasescreen',params)
}
// 筛选条件
export const leaselist = (params) =>{
    return http.post('/house/leaselist',params)
}
// 出租详情
export const leaseinfo = (params) =>{
    return http.get('/house/leaseinfo',params)
}
// 目标租赁房源相似房源列表
export const leasesimilarlist = (params) =>{
    return http.get('/house/leasesimilarlist',params)
}
export const houseleasehotpushlist = (params) =>{
    return http.post('/house/leasehotpushlist',params)
}

